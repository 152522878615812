/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import React from 'react'
import { Box, Image } from 'theme-ui'

import LoneRoseImage from '../images/sexyLoneRose.png'

export const LoneRose = () => {
  return (
    <Box
      sx={{
        minHeight: '190px',
        padding: ['48px 36px', null, '56px 36px'],
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Main style={{ margin: '0 auto', padding: 0 }} variant="compact">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            alt="Sexy lone rose"
            src={LoneRoseImage}
            sx={{ width: '254px', height: '200px', objectFit: 'contain' }}
          />
        </Box>
      </Main>
    </Box>
  )
}
