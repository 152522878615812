import { APP_HOME_PREFIXED_PATH } from '@guess-the-rose/core'
/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import React from 'react'
import { Box, Text } from 'theme-ui'

import { Button } from './Button'

export const FooterCTA = () => {
  return (
    <Box
      sx={{
        minHeight: '190px',
        padding: '26px 36px',
        background: 'linear-gradient(225deg, #C01CA1 0%, #FF044B 100%)',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Main style={{ margin: '0 auto' }} variant="compact">
        <Box
          sx={{
            display: ['flex'],
            alignItems: ['center'],
            justifyContent: ['center'],
            flexDirection: ['column', null, 'row'],
          }}
        >
          <Text
            sx={{
              color: 'white',
              textAlign: ['center', null, null, 'left'],
              fontSize: '18px',
              textTransform: 'uppercase',
              lineHeight: 1.2,
              marginBottom: ['22px', null, 0, 0],
              marginRight: [null, null, null, '24px'],
            }}
          >
            Now open for Season 16 of the Bachelorette
          </Text>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              maxWidth: ['400px', null, null, 'inherit'],
              margin: ['0 auto', null, null, 0],
            }}
          >
            <Button
              as={'a'}
              href={APP_HOME_PREFIXED_PATH}
              style={{
                background: '#322830',
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Main>
    </Box>
  )
}
